import React, { useState } from "react";
import { Link } from "gatsby";
import TwoColumnsLayout from "../TwoColumnsLayout/TwoColumnsLayout";
// images
import arrow from "../../../static/assets/images/arrow.svg";
import share from "../../../static/assets/images/share-mobile.svg";

// Imported Style
import "./productCategory.scss";
const CategoryProduct = (props) => {
  const [copySuccess, setCopySuccess] = useState(null);

  let rootLink = "";
  if (typeof window !== "undefined") {
    rootLink = window.location.origin + "" + window.location.pathname + "/";
  }

  const copyToClipboard = () => {
    const link =
      rootLink + props.data.categoryTeaserShareButton?.buttonLink.categorySlug;
    navigator.clipboard.writeText(link);
    setCopySuccess("Link copied!");
    setTimeout(() => {
      setCopySuccess(null);
    }, 2000);
  };
  const twoColumnLayoutContent = {
    leftContent: (
      <>
        <img
          src={`https:${props.data?.categoryTeaserImage?.file.url}`}
          alt={props.data?.categoryTeaserImage?.description}
          className="categoryImage"
          loading="lazy"
        />
      </>
    ),
    rightContent: (
      <div className="categoryInfo">
        <div className="title">
          <h3>{props.data.category.categoryInnerTitle}</h3>
          <div className="svg-container" onClick={copyToClipboard}>
            <div className={copySuccess ? "no-display" : "mobile-text"}>
              {props.data.categoryTeaserShareButton?.buttonText}
            </div>
            {copySuccess && (
              <div className="success">
                {"Link "}
                <br />
                {"copied"}
              </div>
            )}

            <svg
              width="27"
              height="26"
              viewBox="0 0 27 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.34319 17.6569L17.6569 6.34317M17.6569 6.34317L17.6569 14.8285M17.6569 6.34317L9.17161 6.34317"
                stroke="#b5190e"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <div className="mobile-share">
              {!copySuccess && (
                <img src={share} alt="share icon" loading="lazy" />
              )}
            </div>
          </div>
        </div>
        <p className="info">
          {props.data.categoryTeaserDescription.internal.content}
        </p>
        <div className="click-buttons">
          {props.data.category.categoryProducts.map((productItem, i) => {
            return (
              <Link
                to={`/products/${props.data.category.categorySlug}/${productItem.product.productSlug}`}
                key={productItem.id}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{productItem.product.productInnerTitle}</span>
                <img src={arrow} alt="redirect arrow" loading="lazy" />
              </Link>
            );
          })}
        </div>
        <div className="btn product-button">
          <Link
            to={`/products/${props.data.categoryTeaserViewMoreButton.buttonLink.categorySlug}`}
            className="btn-primary"
          >
            {props.data.categoryTeaserViewMoreButton.buttonText}
          </Link>
        </div>
      </div>
    ),
  };

  return (
    <div className="productsCategory">
      <TwoColumnsLayout
        leftContent={twoColumnLayoutContent.leftContent}
        rightContent={twoColumnLayoutContent.rightContent}
        addedClass={`products-category ${
          props.isRowReversed ? "reverse-row" : ""
        }`}
      />
    </div>
  );
};

export default CategoryProduct;
